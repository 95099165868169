import React, { useState } from 'react'
import PropTypes from 'prop-types'
import tw, { css } from 'twin.macro'
import { Link, graphql } from 'gatsby'
import qs from 'qs'

import Layout from '../../components/layout'
import Meta from '../../components/meta'
import filterLocale from '../../components/utils/filter-locale'
import SupportSearch from '../../components/support/support-search'
import Tabs from '../../components/support/tabs'
import {
	ContentsSection,
	ContentsContainer,
	TextsImageContainer,
	Header,
	HeaderSubText,
	TextsContainer,
	LogoContainer,
	DealersContainer,
	DealerLogoContainer,
} from '../../components/dealer-search/styles'
import TypeInput from '../../components/dealer-search/type-input'
import AddressInput from '../../components/dealer-search/address-input'
import MapBox from '../../components/dealer-search/map-box'
import {
	dealerTypes,
	useDealerSearch,
	useAddressSearch,
} from '../../components/dealer-search/utils'

/**
 * Find a Dealer - Page
 * @param {object} props
 * @param {object} props.data - static query data
 * @param {object} props.location - browser location api
 * @param {string} props.location.search - url params
 * @returns {JSX.Element} FindDealer page
 * @see staticQuery
 */
const FindDealer = ({
	data: { finddealer, authorised, authdealers },
	location: { search },
}) => {
	const locale = 'en-AU'
	const [{ node }] = filterLocale(finddealer.edges, locale)
	const [{ node: auth }] = filterLocale(authorised.edges, locale)
	const [{ node: authdeal }] = filterLocale(authdealers.edges, locale)

	const { seoTitle, seoKeywords, seoDescription } = node

	const { type } = search
		? qs.parse(search, { ignoreQueryPrefix: true })
		: { address: '', type: '' }
	const [queryAddress, onQueryAddress] = useState('')
	const [selectAddress, onSelectAddress] = useState({
		name: '',
		coordinates: [],
	})
	const [selectType, onSelectType] = useState(
		type ? dealerTypes.filter(({ name }) => name === type)[0] : dealerTypes[0]
	)
	const addresses = useAddressSearch(queryAddress, 'en-AU')
	const [dealer, setDealer] = useState({})
	const [selectDealer, onSelectDealer] = useState(0)
	const handleSelectDealer = i => {
		setDealer(dealers[i])
		onSelectDealer(i)
	}
	const { dealers, loading } = useDealerSearch(
		selectType.type,
		selectAddress.coordinates,
		setDealer,
		'en-AU',
		3
	)
	
	return (
		<Layout>
			<Meta
				title={seoTitle}
				keywords={seoKeywords}
				description={seoDescription}
			/>
			<SupportSearch />
			<Tabs locale={locale} />
			<ContentsSection>
				<ContentsContainer>
					<TextsImageContainer>
						<TextsContainer>
							<Header>Elite Dealer Search</Header>
							<HeaderSubText>
								Are you dealing with an Authorised Maytronics Dealer? Look for
								these logos for certainty.
							</HeaderSubText>

							<LogoContainer>
								{auth.items.map((x, i) => {
									return (
										<img
											key={i}
											src={x.image.file.url}
											alt="Authorised Maytronics Dealer"
										/>
									)
								})}
							</LogoContainer>
						</TextsContainer>
					</TextsImageContainer>

					<div tw="flex flex-row w-full flex-wrap items-center justify-center font-display pb-6">
						<div tw="flex flex-col md:flex-row md:space-x-4 items-end justify-center w-full h-full text-gray-800">
							<div tw="w-full md:w-64 md:-mb-3">
								<AddressInput
									{...{
										queryAddress,
										onQueryAddress,
										addresses,
										selectAddress,
										onSelectAddress,
										placeholder: 'address',
									}}
								/>
							</div>
							<div tw="w-full md:w-64 -mb-3">
								<TypeInput
									{...{
										dealerTypes,
										selectType,
										onSelectType,
									}}
								/>
							</div>
						</div>
					</div>

					<div tw="flex flex-row w-full items-center justify-center pb-8">
						<div tw="w-full">
							<MapBox
								{...{
									loading,
									selectAddress,
									selectType,
									dealers,
									selectDealer,
									handleSelectDealer,
									locale: 'en-AU',
									full: true,
								}}
								tw="mb-0"
							/>
						</div>
					</div>

					<section
						tw="grid grid-rows-3 sb:grid-cols-3 sb:grid-rows-none gap-1"
						css={[dealers && dealers[0] && dealers[0].name && tw`pb-8`]}
					>
						{dealers &&
							dealers[0] &&
							dealers[0].name &&
							dealers.map((dealer, i) => (
								<div
									tw="flex flex-col items-center p-2 rounded-lg hover:bg-gray-100 cursor-pointer xl:flex-row xl:inline-flex xl:items-start"
									css={[selectDealer === i && tw`bg-gray-200`]}
									key={dealer._id}
									onClick={() => handleSelectDealer(i)}
									onKeyDown={() => handleSelectDealer(i)}
									role="button"
									tabIndex="-1"
								>
									<div
										tw="mr-2 p-1"
										css={css`
											width: 200px;
										`}
									>
										{dealer.logo && (
											<img
												id="dealer-logo"
												tw="w-auto mx-auto mb-2 object-contain max-h-[100px]"
												alt={dealer.name}
												src={dealer.logo}
											/>
										)}
									</div>
									<div tw="flex flex-col text-left">
										<h3 tw="mb-2 font-display font-bold text-base text-gray-700">
											{dealer.name}
										</h3>
										{dealer.name === 'Pro-Am Australia' && (
											<h3 tw="mb-2 font-display font-bold text-base text-gray-700">
											[Commercial Dealer]
										</h3>
										)}
										<h4 tw="mb-2 font-display text-sm text-gray-700">
											{`${dealer.address.fullAddress}`}
										</h4>
										<div tw="mb-2 font-display font-bold text-sm text-blue-500 leading-6">
											{dealer.phone}
										</div>
										<div tw="mb-2 font-display font-bold text-sm text-blue-500 leading-6">
											{dealer.email && dealer.email[0]}
										</div>
										{dealer.type === 'elite' && (
											<div
												className="button-group"
												tw="inline-flex space-x-2 mr-auto"
											>
												<a
													tw="inline-flex bg-yellow-500 rounded px-4 py-2 shadow-md font-display font-semibold focus:outline-none focus:shadow-outline-yellow focus:border-yellow-300 transition ease-in-out duration-150 "
													href={
														'https://mydolphin.com.au/' +
														dealer.social.minisite
													}
													target="_blank"
													rel="noreferrer noopener"
												>
													View Profile
												</a>
											</div>
										)}
									</div>
								</div>
							))}
					</section>
					<DealersContainer>
						<HeaderSubText>
							Maytronics Authorised Dealers are trained and local to you to
							provide support and service for your purchase of a Maytronics
							Product. Maytronics Authorised Dealers also have access to
							extended warranty. Only dealers that display the logo below meet
							Maytronics stringent requirements to be authorised. If you are not
							sure if a Dealer is authorised, please contact us direct on{' '}
							<a href="tel:1300693657">1300 693 657</a> or 
							<a href="mailto:infoau@maytronics.com">infoau@maytronics.com</a>.
						</HeaderSubText>
						<HeaderSubText>
							In addition to our Elite Dealers, we also authorise the following
							distribution networks
						</HeaderSubText>

						<DealerLogoContainer>
							{authdeal.items.map((x, i) => {
								return (
									<a
										key={i}
										href={x.url}
										target="_blank"
										rel="noopener noreferrer"
									>
										<img
											src={x.image.file.url}
											alt="Maytronics Authorised Dealers"
										/>
									</a>
								)
							})}
						</DealerLogoContainer>
					</DealersContainer>
				</ContentsContainer>
			</ContentsSection>
		</Layout>
	)
}
/**
 * Page content query
 * @private
 * @static
 * @returns {() => void}
 */
export const staticQuery = graphql`
	query findDealerQuery {
		finddealer: allContentfulPage(
			filter: { contentful_id: { eq: "6LAjCqRqIKB2gF4WIQdY1G" } }
		) {
			edges {
				node {
					seoTitle
					seoKeywords
					seoDescription
					node_locale
					slug
					body {
						json
					}
				}
			}
		}
		authorised: allContentfulFeaturedItems(
			filter: { contentful_id: { eq: "4nSUnNIpIcguqEKR16Pevu" } }
		) {
			edges {
				node {
					items {
						url
						image {
							file {
								url
							}
						}
					}
					node_locale
				}
			}
		}
		authdealers: allContentfulFeaturedItems(
			filter: { contentful_id: { eq: "50phjcIV3OsUVeAvJXDGyG" } }
		) {
			edges {
				node {
					items {
						url
						image {
							file {
								url
							}
						}
					}
					node_locale
				}
			}
		}
	}
`
FindDealer.propTypes = {
	data: PropTypes.shape({
		finddealer: PropTypes.objectOf(PropTypes.array),
		authorised: PropTypes.objectOf(PropTypes.array),
		authdealers: PropTypes.objectOf(PropTypes.array),
	}),
	location: PropTypes.shape({
		search: PropTypes.string,
	}),
}
export default FindDealer
